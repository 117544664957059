import React from 'react';
import '../App.css'
import { NavLink, Outlet } from 'react-router-dom';
import { IoPricetagOutline } from "react-icons/io5";
import { MdSpaceDashboard } from "react-icons/md";
import { FaBloggerB } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
// import { LuCircleDollarSign } from "react-icons/lu";
// import Header from '../Header';

const AdminDashboard = () => {
  return (
    <>
      <div className="container-fluid  m-3 p-2 mt-1 rounded ">
      <div className="row">
          <nav className="col-md-2 d-none d-md-block  bg-white sidebar rounded" style={{ height: '142vh' }}>
          <div className="sidebar-sticky">
            <img src='/logo-black.png' alt='logo'className='mt-3' style={{height:'60px',width:'150px',marginBottom:'20px'}}/>
            <ul className="nav flex-column ">
              <li className="nav-item mb-2 mt-3">
                <NavLink to="/admin/dashboard" className="nav-link">
                    <MdSpaceDashboard className='m-2 bg-light  p-2 ' size={35} />
                  
                    Dashboard
                </NavLink>
              </li>

                <li className="nav-item mb-2 mt-3">
                  <NavLink to="/admin/user" className="nav-link">
                    <FaUser className='m-2 bg-light  p-2 ' size={35} />
                  Users
                  </NavLink>
                </li>

              

              <li className="nav-item mb-2 mt-3">
                <NavLink to="/admin/blog" className="nav-link">
                    <FaBloggerB className='m-2 bg-light  p-2 ' size={35} />
      
                  Blog
                </NavLink>
              </li>

              <li className="nav-item mb-2 mt-3">
                <NavLink to="/admin/payment" className="nav-link">
                    <IoPricetagOutline className='m-2 bg-light p-2'  size={35}/>
                    Pricing
                </NavLink>
              </li>

                {/* <li className="nav-item mb-2 mt-3">
                  <NavLink to="/admin/profile" className="nav-link">
                    <LuCircleDollarSign className='m-2 bg-light  p-2 ' size={35} />
                    Transaction
                  </NavLink>
                </li> */}

              <li className="nav-item mb-2 mt-3">
                <NavLink to="/admin/profile" className="nav-link">
                    <FaUser className='m-2 bg-light  p-2 ' size={35} />
                  Profile
                </NavLink>
              </li>

              

            </ul>
          </div>
        </nav>

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          <Outlet />
        </main>
      </div>
    </div>
    </>
  );
};

export default AdminDashboard;
