import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  const { resetToken } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (!resetToken) {
          toast.error('Reset token is missing.');
          return;
        }

        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${apiKey}/check-reset-token/${resetToken}`);
        if (!response.ok) {
          toast.error('Invalid or expired token.');
          return;
        }
    
        
        await response.json();
    
      } catch (error) {
        console.error('Error fetching reset token:', error.message);
        toast.error('Something went wrong, please try again.');
      }
    };
    

    fetchToken();
  }, [resetToken]);

  const handleOKClick = () => {
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!resetToken) {
        toast.error('Reset token is missing. Please try reloading the page.');
        return;
      }

      console.log('Reset token:', resetToken);

      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${apiKey}/reset-password/${resetToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Your password has been changed. Please click "OK" to log in.');
        toast.success('Password changed successfully. Please click "OK" to log in.');
      } else {
        toast.error(`Error: ${data.message || 'Password reset failed.'}`);
      }
    } catch (error) {
      console.error('Error during password reset:', error.message);
      toast.error('Something went wrong, please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center">
        <form className="p-4" style={{ minWidth: '500px' }} onSubmit={handleSubmit}>
          <img src="/logo-black.png" alt="Logo" style={{ width: '130px', marginLeft: '150px' }} />
          <h1 className="mb-4 mt-2 text-center">Reset Password</h1>
          {success && (
            <div className="alert alert-success text-center">
              <p>{success}</p>
              <button type="button" className="btn btn-success" onClick={handleOKClick}>
                OK
              </button>
            </div>
          )}
          <div className="form-group mb-4">
            <input
              type="password"
              className="form-control"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button type="submit" style={{ borderRadius: '5px', padding: '10px 20px', border: 'rgb(255,255,255)', color: 'rgb(255,255,255)', background: 'linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%' }}>
              Change Password
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
