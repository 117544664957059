import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TiChevronLeft } from "react-icons/ti";
import { FaDollarSign } from "react-icons/fa";
import { TiDownload } from "react-icons/ti";
import { useParams } from 'react-router-dom';

function UserDetails() {
    const { userId } = useParams();
    const location = useLocation();
    const { serialNumber } = location.state || {};
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAllTransactions, setShowAllTransactions] = useState(false);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/user/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user details');
                }
                const data = await response.json();
                setUser(data.user);
                setTransactions(data.transactions);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, [userId]);

    const filteredTransactions = transactions.filter(transaction => {
        return (
            transaction.created_at.toLowerCase().includes(searchQuery.toLowerCase()) ||
            transaction.purchased_images.toString().includes(searchQuery.toLowerCase()) ||
            transaction.amount.toString().includes(searchQuery.toLowerCase())
        );
    });

    const limitedTransactions = showAllTransactions ? filteredTransactions : filteredTransactions.slice(0, 5);

    const toggleShowAllTransactions = () => {
        setShowAllTransactions(!showAllTransactions);
    };

    if (!user) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div>
            <header className='header bg-white rounded'>
                <div className='menu-icon'></div>
                <div className='header-left d-flex '>
                    <Link to="/admin/User" className="back-link m-1">
                        <TiChevronLeft size={29} className="back-icon" />
                    </Link>
                    <h4 className='text-dark m-1'> User Details</h4>
                </div>
                <div className='header-right d-flex align-items-center justify-content-end'>
                    <Link to="/" className="btn btn-outline-dark">
                        Logout
                    </Link>
                </div>
            </header>

            <div className="row mt-3">
                <div className="col-md-5">
                    <div className="card p-4 mt-1">
                        <div className="card-body ">
                            <p className='text-secondary'>Member Name</p>
                            <h2>{user.fullName}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 mt-1 ">
                    <div className="card d-flex p-4">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex  m-2'>
                                <FaDollarSign className='m-1 ' style={{ backgroundColor: "#e1dcfff" }} />
                                <p className='text-secondary '>User Type</p>
                                <div className='mt-5 '>
                                    <h1 className='' style={{ marginLeft: '-90px' }}>Paid</h1>
                                </div>
                            </div>
                            <div className='d-flex m-2 '>
                                <TiDownload className='m-1' style={{ backgroundColor: '#e1dcfff' }} />
                                <p className='text-secondary'>Downloads Left</p>
                                <div className='mt-5'>
                                    <h1 className='' style={{ marginLeft: '-130px' }}>{user.remainingCredits}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="card mt-3">
                        <h2 className='m-3'>Details</h2>
                        <hr />
                        <div className='m-3 '>
                            <p className='text-secondary'>Email</p>
                            <h3>{user.email}</h3>
                            <p className='text-secondary'>Phone Number</p>
                            <h3>{user.phoneNumber}</h3>
                            <p className='text-secondary'>Registration Date</p>
                            <h2>{user.createdAt}</h2>
                            <p className='text-secondary'>User Status</p>
                            <button className='btn btn-primary'>{user.status} Active</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card mt-3">
                        <h2 className='m-3'>Transactions</h2>
                        <input
                            type="text"
                            placeholder="Search transactions"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="form-control mb-3"
                        />
                        <hr />
                        <table className='m-3'>
                            <thead className='bg-light m-1'>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Points Bought</th>
                                    <th>Amount Paid</th>
                                </tr>
                            </thead>
                            <tbody>
                                {limitedTransactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{transaction.created_at}</td>
                                        <td>{transaction.purchased_images}</td>
                                        <td>{transaction.amount}</td>
                                    </tr>
                                ))}
                            </tbody>

                            {filteredTransactions.length > 5 && (
                                showAllTransactions ? (
                                    <button className="btn btn-primary m-3 p-2 " onClick={toggleShowAllTransactions}>Show Less</button>
                                ) : (
                                    <button className="btn btn-primary m-3 p-2 " onClick={toggleShowAllTransactions}>See More</button>
                                )
                            )}
                        </table>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;
