import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${apiKey}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Check your email for password reset instructions.');
        console.log(data.message);
      } else {
        toast.error(`Error: ${data.message}`);
        console.error(data.message);
      }
    } catch (error) {
      toast.error('Error during password reset. Please try again.');
      console.error('Error during password reset:', error.message);
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center">
        <form className="p-4" style={{ minWidth: '500px' }} onSubmit={handleSubmit}>
          <img src="/logo-black.png" alt="Logo" style={{ width: '130px', marginLeft: '150px' }} />
          <h1 className="mb-4 mt-2 text-center">Forgot Password</h1>
          <div className="form-group mb-4">
            <input
              type="email"
              className="form-control"
              placeholder='Enter the email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button type="submit" style={{ borderRadius: "5px", padding: "10px 20px", border: "rgb(255,255,255)", color: "rgb(255,255,255)", background: "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%" }}>
              Reset Password
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
