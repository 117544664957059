import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { TiChevronLeft } from "react-icons/ti";
import "react-quill/dist/quill.snow.css";

const EditBlog = () => {
  const navigate = useNavigate();
  const { blogId } = useParams();
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState({
    image: "",
    title: "",
    content: "",
    date: "",
  });

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true);
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${apiKey}/blog/${blogId}`);

        if (!response.ok) {
          throw new Error("Failed to fetch blog data");
        }

        const blogData = await response.json();
        setBlogData(blogData.blog);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [blogId]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setBlogData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setBlogData((prevData) => ({ ...prevData, image: file }));
  };

  const handleUpdateBlog = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", blogData.title);
      formData.append("content", blogData.content);
      formData.append("date", blogData.date);
      formData.append("image", blogData.image);

      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${apiKey}/update-blog/${blogId}`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Blog updated successfully:", result.blog);
        navigate("/admin/blog");
      } else {
        const error = await response.json();
        console.error("Error updating blog:", error.message);
      }
    } catch (error) {
      console.error("Error updating blog:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <Link to="/admin/blog" className="back-link m-1">
            <TiChevronLeft size={29} className="back-icon" />
          </Link>
          <h4 className='text-dark m-1'> Blog Edit</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>
      <div className="container mt-3 bg-white p-3  rounded">
        <div className="row justify-content-center">
          <div className="col-md-9">

            <form>
              <div className="form-group">
                <label htmlFor="image">Upload Image:</label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  onChange={handleUploadImage}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Title:</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Enter title"
                  value={blogData.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content:</label>
                <ReactQuill
                  value={blogData.content}
                  onChange={(value) => setBlogData((prevData) => ({ ...prevData, content: value }))}
                  formats={quillFormats}
                  modules={quillModules}
                />
              </div>
              <button
                type="button"
                style={{
                  textDecoration: "none",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  border: "rgb(255,255,255)",
                  color: "rgb(255,255,255)",
                  background: "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249) 91.35%)",
                }}
                onClick={handleUpdateBlog}
                className="d-flex justify-content-center align-items-center mt-3 mx-auto"
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update Blog'}
              </button>

            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlog;
