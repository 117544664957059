import React, { useEffect, useState } from "react";
// import Header from "../Header";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchBlogData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${apiKey}/blogs`);

        if (!response.ok) {
          throw new Error("Failed to fetch blog data");
        }

        const blogData = await response.json();

        if (isMounted) {
          const filteredBlogs = blogData.blogs.filter((blog) => !blog.deleted);
          setBlogs(filteredBlogs);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
        // Handle the error, e.g., set an error state
      }
    };

    fetchBlogData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleDeleteIconClick = (blogId) => {
    setSelectedBlogId(blogId);
    setShowDeleteModal(true);
  };

  const handleDeleteBlog = async () => {
    try {
      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${apiKey}/blog/${selectedBlogId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        console.log("Blog deleted successfully");

        // Remove the deleted blog from the state immediately
        setBlogs((prevBlogs) =>
          prevBlogs.filter((blog) => blog._id !== selectedBlogId)
        );
      } else {
        const data = await response.json();
        console.error(`Error deleting blog: ${data.message}`);
      }
    } catch (error) {
      console.error("An error occurred while deleting the blog:", error);
    }

    setSelectedBlogId(null);
    setShowDeleteModal(false);
  };
  return (
    <>
      <header className="header bg-white rounded">
        <div className="header-left">
          <h4 className="text-dark m-1"> Blog Management</h4>
        </div>
        <div className="header-right d-flex align-items-center justify-content-end">
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>

      <div className='bg-white rounded mb-5' style={{height:'90vh'}}>
        <div className="common-div " >
          <div className="d-flex justify-content-end  m-3 p-2">
            <Link
              to="/admin/addblog"
              style={{
                textDecoration: "none",
                marginTop: "10px",
                borderRadius: "5px",
                padding: "10px 20px",
                border: "rgb(255,255,255)",
                color: "rgb(255,255,255)",
                background:
                  "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%",
              }}
            >
              Add Blog
            </Link>
          </div>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "90vh" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                {blogs.length > 0 ? (
                  blogs.map((blog) => (
                    <div key={blog._id} className="col-md-4 mb-3">
                      <div className="card">
                        {blog.image && (
                          <div className="position-relative">
                            <img
                              src={blog.image}
                              alt={blog.title}
                              className="card-img-top img-fluid"
                              style={{
                                objectFit: "cover",
                                height: "300px",
                              }}
                            />
                          </div>
                        )}

                        <div className="card-body d-flex flex-column">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="card-date text-black font-weight-bold m-1 p-2">
                              {new Date(blog.date).toLocaleDateString()}
                            </span>

                            <div className="d-flex align-items-center">
                              <Link
                                to={`/blogs/${blog._id}`}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                                className="d-flex align-items-center"
                              >
                                <FaEye className="cursor-pointer m-3" />
                                <span>{blog.views}</span>
                              </Link>
                              <Link
                                to={`/admin/editblog/${blog._id}`}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                                className="d-flex align-items-center"
                              >
                                <FaEdit className="cursor-pointer m-3" />
                              </Link>
                              <FaTrash
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                  handleDeleteIconClick(blog._id)
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <h5 className="card-title">
                              {blog.title.length > 30 ? `${blog.title.substring(0, 30)}...` : blog.title}
                            </h5>

                          </div>
                        </div>

                        <Link
                          to={`/admin/blogs/${blog._id}`}
                          className="btn btn-primary"
                          style={{
                            borderRadius: "5px",
                            padding: "10px 20px",
                            border: "rgb(255,255,255)",
                            color: "rgb(255,255,255)",
                            background:
                              "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%",
                          }}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <h2 className="text-center">No Blogs Found</h2>
                )}
              </div>
            </div>
          )}

          {showDeleteModal && (
            <div
              className="modal"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete Blog</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShowDeleteModal(false)}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete this blog?</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleDeleteBlog}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
</div>

    </>
  );
};

export default Blog;
