import React from 'react';

const NotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <h1 className="text-center">404 - Page Not Found</h1>
    </div>
  );
};

export default NotFound;

