import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import AdminDashboard from './admin/AdminDashboard';
import Dashboard from './admin/Dashboard';
import Blog from './admin//Blog';
import Payment from './admin/Payment';
import Profilepic from './admin/Profilepic';
import NotFound from './NotFound';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ResetPassword';
import AddBlog from './admin/AddBlog';
import EditBlog from './admin/EditBlog';
import BlogDetail from './admin/BlogDetail';
import User from './admin/User';
import UserDetails from './admin/UserDetails';

const App = () => {
  const [userRole, setUserRole] = useState(null);

  const handleLogin = (role) => {
    setUserRole(role);
    localStorage.setItem('userRole', role);
  };

  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Login onLogin={handleLogin} />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ChangePassword />} />
        {userRole === 'ADMIN' ? (
          <>
            <Route path="/admin" element={<AdminDashboard />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="blog" element={<Blog />} />
              <Route path="addblog" element={<AddBlog />} />
              <Route path="editblog/:blogId" element={<EditBlog />} />
              <Route path="blogs/:blogId" element={<BlogDetail />} />
              <Route path="payment" element={<Payment />} />
              <Route path="profile" element={<Profilepic />} />
              <Route path="User" element={<User />} />
              <Route path="userDetails/:userId" element={<UserDetails />} />

            </Route>
          </>
        ) : null}
        {userRole === 'USER' ? <Route path="/404" element={<NotFound />} /> : null}
      </Routes>
    </Router>
  );
};

export default App;