import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Payment = () => {
  const [perImageCost, setPerImageCost] = useState('');
  const [minimumRechargeValue, setMinimumRechargeValue] = useState('');
  const [freeImages, setFreeImages] = useState('');
  const [freeTestImages, setFreeTestImages] = useState('');
  const [maxImagesLimit, setmaxImagesLimit] = useState('');
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://test.offbg.com:8008/price-details');
        const { perImageCost, minimumRechargeValue, freeImages, freeTestImages, maxImagesLimit } = response.data.priceDetails;
        setPerImageCost(perImageCost);
        setMinimumRechargeValue(minimumRechargeValue);
        setFreeImages(freeImages);
        setFreeTestImages(freeTestImages);
        setmaxImagesLimit(maxImagesLimit);
      } catch (error) {
        console.error('Error fetching price details:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation logic
    const errors = {};
    if (!perImageCost.trim()) {
      errors.perImageCost = 'Per image cost is required';
    }

    if (!minimumRechargeValue.trim()) {
      errors.minimumRechargeValue = 'Minimum recharge value is required';
    }
    if (!freeImages.trim()) {
      errors.freeImages = 'Free images field is required';
    }
    if (!freeTestImages.trim()) {
      errors.freeTestImages = 'Free test images field is required';
    }
    if (!maxImagesLimit.trim()) {
      errors.maxImagesLimit = 'Free maximum images field is required';
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const token = localStorage.getItem('token');

      const response = await axios.put('http://test.offbg.com:8008/update-price', {
        perImageCost,
        minimumRechargeValue,
        freeImages,
        freeTestImages,
        maxImagesLimit
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log(response.data);

      // Change button text after submission
      event.target.querySelector('button[type="submit"]').innerText = 'Saved';
    } catch (error) {
      console.error('Error updating price detail:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let isValid = false;

    // Check if the entered value is a number
    if (!isNaN(value)) {
      isValid = true;
    }

    switch (name) {
      case 'perImageCost':
        setPerImageCost(value);
        setErrors({ ...errors, perImageCost: isValid ? '' : 'Per image cost must be a number' });
        break;
      case 'minimumRechargeValue':
        setMinimumRechargeValue(value);
        setErrors({ ...errors, minimumRechargeValue: isValid ? '' : 'Minimum recharge value must be a number' });
        break;
      case 'freeImages':
        setFreeImages(value);
        setErrors({ ...errors, freeImages: isValid ? '' : 'Free images must be a number' });
        break;
      case 'freeTestImages':
        setFreeTestImages(value);
        setErrors({ ...errors, freeTestImages: isValid ? '' : 'Free test images must be a number' });
        break;
      case 'maxImagesLimit':
        setmaxImagesLimit(value);
        setErrors({ ...errors, maxImagesLimit: isValid ? '' : 'Max images limit must be a number' });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <h4 className='text-dark m-1'> Pricing</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>
      <div className='mt-3 bg-white rounded ' style={{ height: '90vh' }}>
        <div className='container'>
          <form onSubmit={handleSubmit} className="needs-validation" noValidate>
            <div className='row m-3 mt-4'>
              <div className='col-md-6'>
                <div className="p-3 mb-3 mt-5">
                  <p>Cost per images</p>
                  <input
                    className={`p-3 form-control-lg bg-light border-0 rounded flied w-100 ${errors.perImageCost ? 'is-invalid' : (perImageCost && 'is-valid')}`}
                    type="text"
                    name="perImageCost"
                    placeholder="Enter amount"
                    value={perImageCost}
                    onChange={handleChange}
                    required
                  />
                  {errors.perImageCost && <div className="invalid-feedback">{errors.perImageCost}</div>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className="p-3 mb-3 mt-5">
                  <p>Minimum recharge value</p>
                  <input
                    className={`p-3 form-control-lg bg-light border-0 rounded flied w-100 ${errors.minimumRechargeValue ? 'is-invalid' : (minimumRechargeValue && 'is-valid')}`}
                    type="text"
                    name="minimumRechargeValue"
                    placeholder="Enter amount"
                    value={minimumRechargeValue}
                    onChange={handleChange}
                    required
                  />
                  {errors.minimumRechargeValue && <div className="invalid-feedback">{errors.minimumRechargeValue}</div>}
                </div>
              </div>
            </div>

            <div className='row m-1 mt-4'>
              <div className='col-md-6'>
                <div className="p-3">
                  <p>Free Images</p>
                  <input
                    className={`p-3 form-control-lg bg-light border-0 rounded w-100 ${errors.freeImages ? 'is-invalid' : (freeImages && 'is-valid')}`}
                    type="text"
                    name="freeImages"
                    placeholder="Enter number of images"
                    value={freeImages}
                    onChange={handleChange}
                    required
                  />
                  {errors.freeImages && <div className="invalid-feedback">{errors.freeImages}</div>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className="p-3">
                  <p>free test images </p>
                  <input
                    className={`p-3 form-control-lg bg-light border-0 rounded w-100 ${errors.freeTestImages ? 'is-invalid' : (freeTestImages && 'is-valid')}`}
                    type="text"
                    name="freeTestImages"
                    placeholder="Enter number of images"
                    value={freeTestImages}
                    onChange={handleChange}
                    required
                  />
                  {errors.freeTestImages && <div className="invalid-feedback">{errors.freeTestImages}</div>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className="p-3">
                  <p> maxImagesLimit </p>
                  <input
                    className={`p-3 form-control-lg bg-light border-0 rounded w-100 ${errors.maxImagesLimit ? 'is-invalid' : (maxImagesLimit && 'is-valid')}`}
                    type="text"
                    name="maxImagesLimit"
                    placeholder="Enter number of images"
                    value={maxImagesLimit}
                    onChange={handleChange}
                    required
                  />
                  {errors.maxImagesLimit && <div className="invalid-feedback">{errors.maxImagesLimit}</div>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center mt-5">
                <button type="submit" className="custom-button m-2">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Payment;