import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { TiChevronLeft } from "react-icons/ti";
const BlogDetail = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`${apiKey}/blog/${blogId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch blog details');
        }

        const blogDetails = await response.json();
        setBlog(blogDetails.blog);

        const incrementViewCount = async () => {
          const incrementResponse = await fetch(`${apiKey}/${blogId}/increment-view`, {
            method: 'PUT',
          });
          if (!incrementResponse.ok) {
            console.error('Failed to increment view count');
          }
        };
        incrementViewCount();
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };

    fetchBlogDetails();
  }, [blogId]);

  if (!blog) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <Link to="/admin/blog" className="back-link m-1">

            <TiChevronLeft size={29} className="back-icon" />
          </Link>
          <h4 className='text-dark m-1'> Blog Details</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>

      <div className="content-section bg-white mt-2 p-2 rounded">
        <div className="content-header d-flex justify-content-between mt-3 m-2 p-2">
          <h2 className='m-1 p-1'>{blog.title}</h2>
          <div className="d-flex align-items-center">
            {blog.date && (
              <p className="me-3">
                <strong>Date:</strong> {new Date(blog.date).toLocaleDateString()}
              </p>
            )}
            <p className="d-flex align-items-center">
              <FaEye className="text-primary me-1" />
              <span>{blog.views}</span>
            </p>
          </div>
        </div>

        {blog.image && (
          <div className="image-container d-flex justify-content-center">
            <img
              src={blog.image}
              alt={blog.title}
              style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'cover' }}
            />
          </div>
        )}

        <p className='m-3 p-3' dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>
    </div>
  );
};

export default BlogDetail;
