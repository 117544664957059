import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const User = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/all_users`);
            const data = await response.json();
            setUsers(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        const filteredUsers = users.filter(user => {
            const matchesSearchTerm = Object.values(user).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm)
            );
            return matchesSearchTerm;
        });
        setFilteredUsers(filteredUsers);
    }, [users, searchTerm]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    let serialNumber = 1;

    return (
        <>
            <header className='header bg-white rounded'>
                <div className='menu-icon'></div>
                <div className='header-left d-flex'>
                    <h4 className='text-dark m-1'> Users</h4>
                </div>
                <div className='header-right d-flex align-items-center justify-content-end'>
                    <Link to="/" className="btn btn-outline-dark">
                        Logout
                    </Link>
                </div>
            </header>

            <div className='bg-white mt-3 m-1 px-1 rounded'>
                <div className='d-flex justify-content-between align-items-center mt-2 m-3 p-1'>
                    <input
                        id="searchInput"
                        type="text"
                        placeholder="Search..."
                        className="form-control m-1 bg-light"
                        style={{ width: '150px' }}
                        onChange={handleSearch}
                    />
                </div>

                <div>
                </div>

                <table className="table m-1">
                    <thead className='bg-light m-1'>
                        <tr>
                            <th>Sr.no</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>UserType</th>
                            <th>UserStatus</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={user.email} className='bg-white'>
                                <td>{serialNumber + index}</td>
                                <td>
                                 <Link 
    to={{ 
        pathname: `/admin/userDetails/${user._id}`, 
        state: { serialNumber: serialNumber + index } 
    }}
>
    {user.fullName}
</Link>
                                </td>
                                <td>{user.email}</td>
                                <td>{user.phoneNumber ? user.phoneNumber : 'null'}</td>
                                <td>{user.role}</td>
                                <td>{user.UserStatus}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default User;
