import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TiChevronLeft } from "react-icons/ti";
const AddBlog = () => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState({
    image: "",
    title: "",
    content: "",
  });
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setBlogData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setBlogData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddBlog = async () => {
    console.log("Title:", blogData.title); // Log the title
    if (!blogData.title.trim()) {
      setError("Title is required.");
      console.log("Error message set for title."); // Log if error message is set
      return;
    }
    console.log("Title is not empty."); // Log if title is not empty
    if (!blogData.content.trim()) {
      setError("Content is required.");
      return;
    }
    setError(null);

    try {
      const formData = new FormData();
      formData.append("title", blogData.title);
      formData.append("content", blogData.content);
      formData.append("image", blogData.image);

      const apiKey = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${apiKey}/upload-blog`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Blog added successfully:", result.blog);
        navigate("/admin/blog");
      } else {
        const error = await response.json();
        console.error("Error adding blog:", error.message);
      }
    } catch (error) {
      console.error("Error adding blog:", error.message);
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <Link to="/admin/blog" className="back-link m-1">
            <TiChevronLeft size={29} className="back-icon" />
          </Link>
          <h4 className='text-dark m-1'>Add Blog</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>

      <div className="container  p-1 m-1" >
        <div className="row justify-content-center bg-white mt-3 rounded" style={{ height: '89vh' }}>
          <div className="col-md-9">

            <form className=" mt-3">
      
              <div className="form-group">
                <label htmlFor="image">Upload Image:</label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  onChange={handleUploadImage}
                />
                {error && !blogData.image && <p className="text-danger">Image is required.</p>}
              </div>
              <div className="form-group">
                <label htmlFor="title">Title:</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Enter title"
                  value={blogData.title}
                  onChange={handleInputChange}
                />
                {error && !blogData.title.trim() && <p className="text-danger">Title is required.</p>}
              </div>

              <div className="form-group">
                <label htmlFor="content">Content:</label>
                <ReactQuill
                  value={blogData.content}
                  onChange={(value) => setBlogData((prevData) => ({ ...prevData, content: value }))}
                  formats={quillFormats}
                  modules={quillModules}
                />
                {error && !blogData.content.trim() && <p className="text-danger">Content is required.</p>}
              </div>

              {error && (blogData.title.trim() || blogData.content.trim()) && <p className="text-danger">{error}</p>}

              <button
                type="button"
                style={{
                  textDecoration: "none",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  border: "rgb(255,255,255)",
                  color: "rgb(255,255,255)",
                  background:
                    "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%",
                }}
                onClick={handleAddBlog}
                className="d-flex justify-content-center align-items-center mt-3 mx-auto"
              >
                Add Blog
              </button>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default AddBlog;
