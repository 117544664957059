import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { Link } from 'react-router-dom';
import { LuUsers2 } from "react-icons/lu";
import { FaDollarSign } from "react-icons/fa6";

const Dashboard = () => {
  const [monthlyRegistrations, setMonthlyRegistrations] = useState([]);
  const [totalAccountCount, setTotalAccountCount] = useState(0);
  const [totalPurchaseCount, setTotalPurchaseCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFreeCredits, setTotalFreeCredits] = useState(0);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Get current year

  useEffect(() => {
    const fetchTotalPayments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/totalPayments`);
        const data = await response.json();
        setTotalAmount(data.totalAmount);
      } catch (error) {
        console.error('Error fetching total payments:', error);
      }
    };

    fetchTotalPayments();
  }, []);

  useEffect(() => {
    const fetchTotalFreeCredits = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/totalFreeCredits`);
        const data = await response.json();
        setTotalFreeCredits(data.totalFreeCredits);
      } catch (error) {
        console.error('Error fetching total free credits:', error);
      }
    };

    fetchTotalFreeCredits();
  }, []);

  useEffect(() => {
    const renderPercentageGraph = () => {
      const total = totalAmount + totalFreeCredits;
      const paymentsPercentage = (totalAmount / total) * 100;
      const freeCreditsPercentage = (totalFreeCredits / total) * 100;

      const ctx = document.getElementById('percentageChart');
      if (ctx) {
        new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: ['Payments', 'Free Credits'],
            datasets: [{
              data: [paymentsPercentage, freeCreditsPercentage],
              backgroundColor: ['#432cd6', '#9b70ff']
            }]
          },
          options: {
            cutoutPercentage: 80,
            responsive: true,
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Percentage Distribution'
            },
            elements: {
              arc: {
                borderRadius: 10,
              }
            },
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {
                  return Math.round(value) + '%';
                },
                color: '#fff',
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        });
      }
    };

    renderPercentageGraph();
  }, [totalAmount, totalFreeCredits]);

  useEffect(() => {
    const fetchMonthlyRegistrations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/userregistrations/monthly`);
        const data = await response.json();
        setMonthlyRegistrations(data.monthlyRegistrations);
      } catch (error) {
        console.error('Error fetching monthly user registrations:', error);
      }
    };

    fetchMonthlyRegistrations();
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/api/accounts/totalcount`)
      .then(response => response.json())
      .then(data => {
        setTotalAccountCount(data.totalCount);
      })
      .catch(error => {
        console.error('Error fetching total account count:', error);
      });

    fetch(`${process.env.REACT_APP_API_KEY}/api/payment/totalpurchasecount`)
      .then(response => response.json())
      .then(data => {
        setTotalPurchaseCount(data.totalPurchaseCount);
      })
      .catch(error => {
        console.error('Error fetching total purchase count:', error);
      });

    fetch(`${process.env.REACT_APP_API_KEY}/api/revenue`)
      .then(response => response.json())
      .then(data => {
        setTotalRevenue(data.revenue);
      })
      .catch(error => {
        console.error('Error fetching total revenue:', error);
      });
  }, []);

  useEffect(() => {
    const renderMonthlyRegistrationsChart = () => {
      const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const filledLabels = allMonths;
      const filledData = allMonths.map(month => {
        const existingEntry = monthlyRegistrations.find(entry => entry.month === month);
        return existingEntry ? existingEntry.count : 0;
      });

      const barCtx2 = document.getElementById('barChart2');
      if (barCtx2) {
        const barData2 = {
          labels: filledLabels,
          datasets: [{
            label: 'Monthly Users Registrations',
            data: filledData,
            backgroundColor: '#432cd6'
          }]
        };
        const barOptions2 = {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
               
              }
            }],
            xAxes: [{
              ticks: {
                maxRotation: 0,
                minRotation: 0,
              }
            }]
          }
        };
        new Chart(barCtx2, {
          type: 'bar',
          data: barData2,
          options: barOptions2
        });
      }
    };

    if (monthlyRegistrations.length > 0) {
      renderMonthlyRegistrationsChart();
    }
  }, [monthlyRegistrations]);

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/revenue`);
        const data = await response.json();

        const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const filledLabels = allMonths;
        const filledData = allMonths.map(month => {
          const existingEntry = data.purchasePerMonth.find(entry => entry.month === month);
          return existingEntry ? existingEntry.totalAmount : 0;
        });

        const curveCtx = document.getElementById('curveChart');
        if (curveCtx) {
          const curveData = {
            labels: filledLabels,
            datasets: [{
              label: 'Revenue',
              data: filledData,
              borderColor: '#432cd6',
              backgroundColor: 'transparent',
              lineTension: 0.3
            }]
          };
          const curveOptions = {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          };
          new Chart(curveCtx, {
            type: 'line',
            data: curveData,
            options: curveOptions
          });
          curveCtx.style.height = '400px';
        }
      } catch (error) {
        console.error('Error fetching total revenue:', error);
      }
    };

    fetchRevenueData();
  }, []);

  return (
    <>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <h4 className='text-dark m-1'> Dashboard</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>
      <div className="mt-3">
        <div className="card py-1">
          <div className="card-body d-flex">
            <p className='fw-bold'>Insights for {currentYear}</p>
            <div className="d-flex align-items-center justify-content-between">
              <div className='d-flex'>
                <canvas id="percentageChart" style={{ width: '400px', height: '200px' }}></canvas>
              </div>
            </div>

            <div className='d-flex' style={{ marginLeft: '200px' }}>
              <div style={{ borderLeft: '0.5px solid black' }}></div>
              <div>
                <h5 className='p-3'><LuUsers2 className='m-1 bg-light' /> Total Account: </h5>
                <h3 className='p-3' style={{ marginLeft: '70px' }}> {totalAccountCount} </h3>
              </div>
              <div style={{ borderLeft: '0.5px solid black' }}></div>
              <div>
                <h5 className='p-3'><FaDollarSign className='m-1 bg-light' /> Total Purchase: </h5>
                <h3 className='p-3' style={{ marginLeft: '70px' }}> {totalPurchaseCount} </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <h5>Monthly Users Registrations {currentYear}</h5>
            <div className='chart-container'>
              <canvas id="barChart2" style={{ width: '1000px', height: '200px' }}></canvas>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <h5>Revenue {currentYear}</h5>
            <div className='chart-container'>
              <canvas id="curveChart" style={{ width: '1000px', height: '500px' }}></canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
