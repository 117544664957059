import React from 'react'
import { Link } from 'react-router-dom';

const Profilepic = () => {
  return (
    <>
      <header className='header bg-white rounded'>
        <div className='menu-icon'></div>
        <div className='header-left d-flex '>
          <h4 className='text-dark m-1'> Profile</h4>
        </div>
        <div className='header-right d-flex align-items-center justify-content-end'>
          <Link to="/" className="btn btn-outline-dark">
            Logout
          </Link>
        </div>
      </header>
 
    <div> </div>
    </>
  )
}

export default Profilepic