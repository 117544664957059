import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };
  const handleLogin = async () => {
    try {
      const url = process.env.REACT_APP_API_KEY;
      const response = await fetch(`${url}/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const { token, role } = data.responseData;
        onLogin(role);
        localStorage.setItem('token', token);

        if (role === 'ADMIN') {
          navigate('/admin/dashboard');
        } else {
          navigate('/404');
        }

        toast.success('Login successful');
      } else {
        console.error(data.message);

        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error during login:', error.message);

      toast.error('Error during login');
    }
  };

  return (
    <>
      <div className="container mt-5">
        <div className="d-flex justify-content-center align-items-center">
          <form className="p-4" style={{ minWidth: '500px' }} onSubmit={handleSubmit}>
            <img src="/logo-black.png" alt="Logo" style={{ width: '130px', marginLeft: '150px' }} />
            <h1 className="mb-4 mt-2 text-center">Admin Login</h1>
            <div className="form-group mb-4">
              <input
                type="email"
                className="form-control"
                placeholder='Enter your mail'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mb-4">
              <input
                type="password"
                className="form-control"
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <small className="form-text text-muted mt-2">
                <NavLink to="/forgot-password">Forgot Password?</NavLink>
              </small>
            </div>
            <div className="text-center mt-2">
              <button type="submit" style={{ borderRadius: "5px", padding: "10px 20px", border: "rgb(255,255,255)", color: "rgb(255,255,255)", background: "linear-gradient(40.28deg,rgb(4,93,233) 11.41%,rgb(9,198,249)91.35%" }}>
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
